import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { client } from '../lib/client';
import { format } from 'date-fns';
import { PortableText } from '@portabletext/react';
import urlBuilder from '@sanity/image-url';
import { getImageDimensions } from '@sanity/asset-utils';

const Blogpost = () => {
  const [blogpost, setBlogpost] = useState([]);
  const { slug } = useParams();
  const [imageSrc, setImageSrc] = useState();

  useEffect(() => {
    client
      .fetch(
        `*[slug.current == "${slug}"] {
        title,
        slug,
        body,
           image{
        asset->{
          _id,
          url
        },
        alt
      },
        name,
        author,
        publishedAt,
        mainImage {
          asset -> {
            _id,
            url
          },
          alt
        },
        'name': author -> name,
      }`
      )
      .then((data) => {
        setBlogpost(data[0]);
      })
      .catch(console.error);
  }, [slug]);

  useEffect(() => {
    document.title = `Публикация | ${blogpost.title}`;
  }, [blogpost.title]);

  const SampleImageComponent = ({ value }) => {
    const { width, height } = getImageDimensions(value);
    return (
      <img
        src={urlBuilder()
          .image(value)
          .width(600)
          .fit('max')
          .auto('format')
          .url()}
        alt="OOooops "
        loading="lazy"
        style={{
          aspectRatio: width / height,
        }}
      />
    );
  };

  console.log(blogpost.image);

  return (
    <>
      {blogpost && (
        <section className="pb-20 pt-32 px-5 max-w-3xl mx-auto">
          {blogpost.mainImage && (
            <img
              src={blogpost.mainImage.asset.url}
              alt={blogpost.mainImage.alt}
              className="h-2/3 w-full object-cover rounded-2xl shadow-lg "
            />
          )}
          <h1 className="font-bold text-4xl my-8 xl:text-6xl ">
            {blogpost.title}
          </h1>
          <p className="text-sm font-bold mb-8">
            Автор {blogpost.name}{' '}
            {blogpost.publishedAt && (
              <>
                &middot;{''}
                {format(new Date(blogpost.publishedAt), 'dd MM yyyy')}
              </>
            )}
          </p>
          <div>
            <PortableText
              value={blogpost.body}
              // components={{
              //   types: {
              //     image: SampleImageComponent,
              //   },
              // }}
            />
          </div>

          <div className="max-w-7xl mx-auto mt-10 px-5 mb-20 flex items-end justify-end">
            <Link
              to="/blog"
              className="dark:bg-purple-800 dark:text-white bg-white py-2 px-8 rounded shadow text-slate-800 tracking-wide font-bold hover:opacity-75 transition-all duration-300 w-full md:w-auto"
            >
              Все публикации
            </Link>
          </div>
        </section>
      )}
    </>
  );
};

export default Blogpost;
