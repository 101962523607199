import React, { useState, useEffect } from 'react';

import Footer from '../components/Footer';
import { client } from '../lib/client';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';

import Hero from '../components/Hero';
import MyVideo from '../components/MyVideo';
import MyMusic from '../components/MyMusic';
import Intro from '../components/Intro';
import MyGallery from '../components/MyGallery';
import Partners from '../components/Partners';

const Homepage = () => {
  const [stories, setStories] = useState([]);

  useEffect(() => {
    client
      .fetch(
        `*[_type == "post"] {
        title,
        slug,
        body,
        name,
        author,
        publishedAt,
        mainImage {
          asset -> {
            _id,
            url
          },
          alt
        },
        'name': author -> name,
      } | order(publishedAt desc)`
      )
      .then((data) => {
        setStories(data.slice(0, 3));
      })
      .catch(console.error);
  }, []);

  useEffect(() => {
    document.title = 'Cherta Duality. Создаем и рассказываем.';
  }, []);

  return (
    <>
      {/* <Navbar /> */}
      <div className="hidden xl:block ">
        <Hero />
      </div>
      <Intro />
      <section className="pb-8 max-w-6xl mx-auto " id="posts">
        <h1 className="text-5xl font-bold my-8 border-b-2 pb-4 text-center items-center">
          Заметки
        </h1>

        {!stories ? (
          <h2>Loading...</h2>
        ) : (
          <>
            {stories[0] && (
              <Link to={`/blog/${stories[0].slug.current}`}>
                <section className="max-w-7xl md:block hidden mx-auto pt-20 mb-20 px-5 z-2 transition-all duration-300 hover:opacity-85">
                  <article className="relative ">
                    {stories[0].mainImage && (
                      <img
                        src={stories[0].mainImage.asset.url}
                        alt={stories[0].mainImage.alt}
                        className="h-96 w-full object-cover rounded-2xl "
                      />
                    )}
                    <div className="absolute bottom-8 left-8 ">
                      <h1 className="text-4xl lg:text-8xl  mb-6 text-white font-bold drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,1)]">
                        {stories[0].title}
                      </h1>
                      <p className="text-slate-100 mb-8 md:w-1/2">
                        {`${stories[0].body[0].children[0].text.substring(
                          0,
                          200
                        )}...`}
                      </p>
                      <Link
                        to={`/blog/${stories[0].slug.current}`}
                        className="bg-white py-2 px-8 rounded shadow text-slate-800 tracking-wide font-bold hover:opacity-75 transition-all duration-300 w-full md:w-auto"
                      >
                        Читать далее
                      </Link>
                    </div>
                  </article>
                </section>
              </Link>
            )}
          </>
        )}

        <section className=" grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3 max-w-7xl mx-auto px-5 mb-10 ">
          {stories.map((story) => (
            <Link to={`/blog/${story.slug.current}`} key={story.slug.current}>
              <article className="hover:opacity-85 dark:hover:bg-slate-800 dark:border-slate-800 border border-slate-400 rounded-lg overflow-hidden hover:bg-slate-100 transition-all duration-300">
                {story.mainImage && (
                  <img
                    src={story.mainImage.asset.url}
                    alt={story.mainImage.alt}
                    loading="lazy"
                    className="md:h-64 w-full object-cover"
                  />
                )}

                <div className="p-4">
                  <p className="text-sm">
                    Автор {story.name}{' '}
                    {story.publishedAt && (
                      <>
                        &middot;{''}
                        {format(new Date(story.publishedAt), 'dd MM yyyy')}
                      </>
                    )}
                  </p>
                  <h2 className="text-xl  my-2 font-bold ">{story.title}</h2>
                  <p className="text-sm leading-relaxed">
                    {`${story.body[0].children[0].text.substring(0, 200)}...`}
                  </p>
                </div>
              </article>
            </Link>
          ))}
        </section>

        <div className="max-w-7xl mx-auto px-5 mb-20 flex items-end justify-end">
          <Link
            to="/blog"
            className="dark:bg-purple-800 dark:text-white bg-white py-2 px-8 rounded shadow text-slate-800 tracking-wide font-bold hover:opacity-75 transition-all duration-300 w-full md:w-auto"
          >
            Все публикации
          </Link>
        </div>
      </section>

      {/* <MyGallery /> */}
      <MyVideo />
      <MyMusic />
      <Partners />
      <Footer />
    </>
  );
};

export default Homepage;
