import React from 'react';
import { Link } from 'react-router-dom';
import sadMusician from './assets/pics/dc46be6d-3b56-4a03-8e62-07ea1b16c3b1.png';

const MyMusic = () => {
  return (
    <>
      <section
        className="max-w-6xl mx-auto justify-between text-center items-center mb-16"
        id="music"
      >
        <h1 className="text-5xl font-bold my-8 border-b-2 pb-4">Музыка</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="flex flex-col gap-14   items-center text-center h-72 overflow-scroll scrollbar-hide">
            <button className="hover:opacity-55 hover:shadow-md hover:scale-105 transition duration-300">
              <Link to="https://band.link/ONmew">
                <img
                  src="https://music-bandlink.s3.yandex.net/img/bandlinks/ONmew_JYUUqfDt_370x370.jpeg"
                  alt=""
                  className="h-64"
                />
              </Link>
            </button>
            <button className="hover:opacity-55 hover:shadow-md hover:scale-105 transition duration-300">
              <Link to="https://band.link/rV7G5">
                <img
                  src="https://is1-ssl.mzstatic.com/image/thumb/Music221/v4/95/c7/81/95c7818d-755b-097a-462e-42be979b568d/cover.jpg/500x500bb.jpg"
                  alt=""
                  className="h-64"
                />
              </Link>
            </button>
            <button className="hover:opacity-55 hover:shadow-md hover:scale-105 transition duration-300">
              <Link to="https://band.link/NSA0U">
                <img
                  src="https://is1-ssl.mzstatic.com/image/thumb/Music126/v4/51/58/8a/51588aee-8d5e-c457-0b20-a290baabe10b/cover.jpg/500x500bb.jpg"
                  alt=""
                  className="h-64"
                />
              </Link>
            </button>
            <button className="hover:opacity-55 hover:shadow-md  transition duration-300">
              <img className="h-64" src={sadMusician} alt="" />
              <p>Пока что новых песен нет</p>
            </button>
          </div>
          <div className="justify-center items-center text-center my-auto">
            <p>
              Какие-то новые песни тут появляются крайне редко и, как правило,
              скорее являются данью уважения более активному творческому
              прошлому, но все еще остаются чем-то глубоко личным. Однако, в
              зависимости от настроения и происходящих событий, многие,
              вероятно, смогут найти здесь и нечто, близкое им.
              <br />
              <br />
              Не сдерживайте себя, листайте картинки вниз. <br />
              Приятного прослушивания.
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default MyMusic;
