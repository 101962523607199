import React, { useEffect, useState } from 'react';
import { FaTelegram } from 'react-icons/fa';
import { MdAlternateEmail } from 'react-icons/md';
import { FaYoutube } from 'react-icons/fa';
import { PiMusicNoteFill } from 'react-icons/pi';
import { Link } from 'react-router-dom';
import whiteLogo from './assets/pics/logo-w.png';
import darkLogo from './assets/pics/logo.png';
import Menu from './Menu';

const Navbar = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  const [scrollTop, setScrollTop] = useState(0);
  useEffect(() => {
    const handleScroll = (event) => {
      setScrollTop(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <div className="w-full backdrop-blur-md shadow-xl z-10 fixed h-24 px-5 bg-slate-200 dark:bg-black dark:bg-opacity-40 bg-opacity-40">
        <section className="max-w-6xl mx-auto justify-between">
          <div className="hidden md:block">
            {window.scrollY > 800 ? (
              <Link
                to="/"
                className="animate-fade-in absolute top-4 "
                onClick={scrollToTop}
              >
                <img
                  className="hidden dark:block w-16 md:w-32 "
                  src={whiteLogo}
                  alt="logo"
                />
                <img
                  className="block dark:hidden w-16 md:w-32  "
                  src={darkLogo}
                  alt="logo"
                />
              </Link>
            ) : (
              ''
            )}
          </div>

          <div className="block md:hidden">
            <Link
              to="/"
              className="animate-fade-in absolute top-4 "
              onClick={scrollToTop}
            >
              <img
                className="hidden dark:block w-16 md:w-32 "
                src={whiteLogo}
                alt="logo"
              />
              <img
                className="block dark:hidden w-16 md:w-32  "
                src={darkLogo}
                alt="logo"
              />
            </Link>
          </div>

          <ul className="flex items-center justify-end gap-4 mt-8 ">
            <Menu />
            <li>
              <Link to="https://t.me/chertaduality_official">
                <FaTelegram className="hover:scale-105 transition-all duration-300 text-3xl text-slate-800 dark:text-slate-400  hover:text-purple-900" />
              </Link>
            </li>
            <li>
              <Link to="https://www.youtube.com/channel/UCaXttUBZmLfXPe0roX-w5Xw">
                <FaYoutube className="hover:scale-105 transition-all duration-300 text-3xl text-slate-800 dark:text-slate-400  hover:text-purple-900" />
              </Link>
            </li>
            <li>
              <Link to="https://music.yandex.ru/artist/21027248?utm_source=desktop&utm_medium=copy_link">
                <PiMusicNoteFill className="hover:scale-105 transition-all duration-300 text-3xl text-slate-800 dark:text-slate-400   hover:text-purple-900" />
              </Link>
            </li>
            <li>
              <Link to="mailto:cherta.duality@yandex.ru">
                <MdAlternateEmail className="hover:scale-105 transition-all duration-300 text-3xl text-slate-800 dark:text-slate-400   hover:text-purple-900" />
              </Link>
            </li>
          </ul>
        </section>
      </div>
    </>
  );
};

export default Navbar;
