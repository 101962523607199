import React, { useState } from 'react';
import { Meteors } from './Meteors';
import { MeteorPreview } from './MeteorPreview';

const Intro = () => {
  const [opening, setOpening] = useState(false);

  return (
    <>
      <section className="px-5 pt-32 pb-16 md:pt-16 max-w-3xl mx-auto text-center justify-center items-center">
        <button
          className="dark:bg-purple-800 dark:text-white bg-white py-2 px-8 rounded shadow text-slate-800 tracking-wide font-bold hover:opacity-75 transition-all duration-300 w-full md:w-auto mb-10"
          onClick={() => setOpening(!opening)}
        >
          {!opening ? 'Прочитать послание' : 'Скрыть послание'}
        </button>

        {opening ? (
          <div>
            <div className=" relative ">
              <div className="absolute inset-0 h-full w-full bg-gradient-to-r from-blue-500 to-teal-500 transform scale-[0.80] bg-red-500 rounded-full blur-3xl" />
              <div className="relative shadow-xl bg-gray-900 border border-gray-800  px-4 py-8 h-full overflow-hidden rounded-2xl flex flex-col justify-end items-start">
                <p>
                  Мы все живем в удивительное время, но даже не задумываемся над
                  этим.
                  <br />
                  <br />
                  Скорость технического прогресса раскручивает свой маховик все
                  сильнее с каждым днем. Какое количество захватывающих вещей и
                  событий окружает нас в нашей повседневной жизни?
                  <br />
                  <br />
                  А ведь еще так много всего интересного пролетает мимо нашего
                  взора.
                  <br />
                  <br />
                  Мы собрали небольшой авторский коллектив и хотим, по мере сил,
                  рассказать людям об удивительных вещах из области технологий,
                  об истории гаджетов, о том, что можно сделать своими руками,
                  если приложить смекалку. Помимо этого мы хотим рассказать об
                  интересных книгах, фильмах и иных формах творчества, которые,
                  по нашему мнению, заслуживают внимания. Ну и про свой креатив
                  расскажем тоже, конечно же.
                  <br />
                  <br />
                  Мы надеемся на то, что каждый, кто попал сюда, сможет найти
                  для себя интересную тему и почерпнуть что-то, что его
                  восхитит.
                  <br />
                  <br />
                  Мы хотим рассказать как можно большему количеству людей о том,
                  на что способно воображение и жажда знаний, чтобы разделить с
                  вами радость от каждого, даже малозначительного достижения,
                  ведь ничего нет ценнее опыта, так давайте же разделим этот
                  опыт со всем миром!
                  <br />
                  <br />
                  P.S. Для самых внимательных и любознательных на сайте будут
                  спрятаны некоторые занимательные "пасхалки".
                </p>
                <button
                  className="dark:bg-purple-800 dark:text-white bg-white py-2 px-8 rounded shadow text-slate-800 tracking-wide font-bold hover:opacity-75 transition-all duration-300 w-full md:w-auto mt-10 mx-auto text-center justify-center items-center"
                  onClick={() => setOpening(!opening)}
                >
                  {!opening ? 'Прочитать послание' : 'Скрыть послание'}
                </button>
                <Meteors number={20} />
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
      </section>
    </>
  );
};

export default Intro;
