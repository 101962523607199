import React from 'react';

const Partners = () => {
  const images = require.context('./assets/pics/partners/', true);
  const imageList = images.keys().map((image) => images(image));
  return (
    <>
      <section
        className="max-w-6xl mx-auto justify-between text-center items-center mb-16 px-2"
        id="partners"
      >
        <h1 className="text-5xl font-bold my-8 border-b-2 pb-4">
          Вот кому уже помогаем:
        </h1>
        <div className="max-w-3xl px-5 grid md:grid-cols-3 sm:grid-cols-2  grid-cols-1 m-auto justify-center image-center text-center gap-6">
          {imageList.map((image, index) => (
            <img
              className="md:max-w-[250px] px-1 md:px-4 md:max-h-[250px] max-w-[200px] max-h-[200px] hover:opacity-80 transition-all duration-300 m-auto justify-center"
              key={index}
              src={image}
              alt={`image-${index}`}
            />
          ))}
        </div>
      </section>
    </>
  );
};

export default Partners;
