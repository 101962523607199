import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Homepage from './pages/Homepage.js';
import Blog from './pages/Blog';
import Blogpost from './pages/Blogpost';
import Error from './pages/Error';
import ScrollToTop from './components/ScrollToTop.js';
import Navbar from './components/Navbar.js';
// import { HashLink as Link } from 'react-router-hash-link';

export default function App() {
  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <Navbar />
        <Routes>
          <Route path="/" element={<Homepage />}></Route>
          <Route path="/blog" element={<Blog />}></Route>
          <Route path="/blog/:slug" element={<Blogpost />}></Route>
          <Route path="*" element={<Error />}></Route>
          {/* <Route path="/#posts"></Route> */}
        </Routes>
      </BrowserRouter>
    </>
  );
}
