import clsx from 'clsx';
import React from 'react';

export const Meteors = ({ number }: { number?: number }) => {
  const meteors = new Array(number || 20).fill(true);
  return (
    <>
      {meteors.map((el, idx) => (
        <span
          key={'meteor' + idx}
          className={clsx(
            'animate-meteor-effect absolute top-0 left-0 h-0.5 w-0.5 rounded-[9999px] bg-slate-500 shadow-[0_0_0_1px_#ffffff10] rotate-[215deg]',
            "before:content-[''] before:absolute before:top-1/2 before:transform before:-translate-y-[50%] before:w-[50px] before:h-[1px] before:bg-gradient-to-r before:from-[#64748b] before:to-transparent"
          )}
          style={{
            top: 0,
            left: Math.floor(Math.random() * (900 - -400) + -400) + 'px',
            animationDelay: Math.random() * (0.9 - 0.1) + 0.5 + 's',
            animationDuration: Math.floor(Math.random() * (30 - 2) + 2) + 's',
          }}
        ></span>
      ))}
    </>
  );
};
