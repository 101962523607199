import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ScrollToAnchor from './ScrollToAnchor';
import { FiMenu } from 'react-icons/fi';
import { MdClose } from 'react-icons/md';
import { FaHome } from 'react-icons/fa';

const Menu = () => {
  const [showMenu, setShowMenu] = useState(false);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <>
      <section className="md:flex flex-row gap-6 hidden ">
        <Link
          to="/"
          onClick={scrollToTop}
          className="hover:scale-105 transition-all duration-300 text-3xl text-slate-800 dark:text-slate-400   hover:text-purple-900"
        >
          <FaHome />
        </Link>
        <Link
          to="/#posts"
          className="font-bold text-slate-800 dark:text-slate-400  hover:text-purple-900 transition-all duration-300"
          onClick={ScrollToAnchor()}
        >
          Заметки
        </Link>
        <Link
          to="/#video"
          className="font-bold text-slate-800 dark:text-slate-400  hover:text-purple-900 transition-all duration-300"
          onClick={ScrollToAnchor()}
        >
          Видео
        </Link>
        <Link
          to="/#music"
          className="font-bold text-slate-800 dark:text-slate-400  hover:text-purple-900 transition-all duration-300"
          onClick={ScrollToAnchor()}
        >
          Музыка
        </Link>
        <Link
          to="/#partners"
          className="font-bold text-slate-800 dark:text-slate-400  hover:text-purple-900 transition-all duration-300"
          onClick={ScrollToAnchor()}
        >
          Сотрудничаем
        </Link>
      </section>

      <section>
        <span
          onClick={() => setShowMenu(!showMenu)}
          className="text-xl md:hidden  w-10 h-10 inline-flex items-center justify-center cursor-pointer  text-slate-800 dark:text-slate-400  hover:text-purple-900 transition-all duration-300"
        >
          <FiMenu />
        </span>
        {showMenu && (
          <div className="w-full items-center mx-auto text-center justify-center font-bold text-3xl pt-32 overflow-scroll absolute top-0 left-0 p-14 scrollbar-hide bg-slate-200 dark:bg-black dark:bg-opacity-90 bg-opacity-90">
            <div className="flex flex-col gap-8 py-2 relative">
              <ul className="flex flex-col gap-4">
                <li className="my-4" onClick={() => setShowMenu(false)}>
                  <Link to="/" onClick={scrollToTop}>
                    <h1 className="text-6xl font-bold text-slate-800 dark:text-slate-400  hover:text-purple-900 transition-all duration-300">
                      Домой
                    </h1>
                  </Link>
                </li>
                <li className="my-4" onClick={() => setShowMenu(false)}>
                  <Link to="/#video">
                    <h1 className="text-6xl font-bold text-slate-800 dark:text-slate-400  hover:text-purple-900 transition-all duration-300">
                      Видео
                    </h1>
                  </Link>
                </li>
                <li className="my-4" onClick={() => setShowMenu(false)}>
                  <Link to="/#music">
                    <h1 className="text-6xl font-bold text-slate-800 dark:text-slate-400  hover:text-purple-900 transition-all duration-300">
                      Музыка
                    </h1>
                  </Link>
                </li>
                <li className="my-4" onClick={() => setShowMenu(false)}>
                  <Link
                    to="/#partners"
                    className="font-bold text-slate-800 dark:text-slate-400  hover:text-purple-900 transition-all duration-300"
                  >
                    <h1 className="text-6xl font-bold text-slate-800 dark:text-slate-400  hover:text-purple-900 transition-all duration-300">
                      Сотрудничаем
                    </h1>
                  </Link>
                </li>
                <li
                  onClick={() => setShowMenu(false)}
                  className="animate-bounce items-center text-center justi mx-auto mt-12 text-slate-800 dark:text-slate-400  hover:text-purple-900 transition-all duration-300 font-bold text-5xl"
                >
                  <MdClose />
                </li>
              </ul>
            </div>
          </div>
        )}
      </section>
    </>
  );
};

export default Menu;
