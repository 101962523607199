import React from 'react';
import { Link } from 'react-router-dom';

const Error = () => {
  return (
    <>
      <section className="px-5 flex items-center justify-center text-center h-screen">
        <article className="max-w-3xl mx-auto">
          <h1 className="text-4xl lg:text-5xl uppercase font-bold mb-8 ">
            Ой-ой! Такой страницы не существует!
          </h1>
          <p className="mb-8">
            Мы еще не успели сделать необходимую страницу... Ну или удалили ее..
          </p>
          <Link to="/" className="underline text-slate-600 dark:text-slate-400">
            Назад на главную страницу
          </Link>
        </article>
      </section>
    </>
  );
};

export default Error;
