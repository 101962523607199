import React from 'react';

const MyVideo = () => {
  const videos = require.context('./assets/vids/videoGallery/', true);
  const posters = require.context('./assets/vids/videoGallery/', true);
  const videoList = videos.keys().map((video) => videos(video));

  return (
    <>
      <section
        className="pb-8 max-w-6xl mx-auto justify-between text-center items-center"
        id="video"
      >
        <h1 className="text-5xl font-bold my-8 border-b-2 pb-4 ">Видео</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 ">
          <div className="justify-center items-center text-center my-auto ">
            <p>
              В этом разделе публикуются видео абсолютно разного характера. Тут
              есть и клипы, и интервью, и обзорные видео с мероприятий, и многое
              другое. Листайте ролики вниз, не стесняйтесь. <br />
              <br />
              Приятного просмотра.
            </p>
          </div>
          <div className="h-[200px] md:h-[340px] overflow-x-hidden scrollbar-hide">
            {videoList.map((video, index) => (
              <div>
                <video
                  controls
                  width="100%"
                  height="100%"
                  preload="auto"
                  className="my-14  rounded-xl overflow-hidden shadow-lg"
                  key={index}
                  src={video}
                  type="mp4"
                />
              </div>
            ))}
            {/* <iframe
              className="my-8"
              width="530"
              height="315"
              src="https://www.youtube.com/embed/U0iitVbiGH4?si=A-Kv73x4H_RficwC"
              // allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            ></iframe>
            <iframe
              className="my-8"
              width="530"
              height="315"
              src="https://www.youtube.com/embed/CCBpq3inca4?si=ZHuswPsyUVft3C7d"
              // allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            ></iframe>
            <iframe
              className="my-8"
              width="530"
              height="315"
              src="https://www.youtube.com/embed/3q-nMvK8vU4?si=dOCdfoi142MqB-It"
              // allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            ></iframe>
            <iframe
              className="my-8"
              width="530"
              height="315"
              src="https://www.youtube.com/embed/6pVLI6eqitM?si=ObkCMy4ZoH1t343F"
              // allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            ></iframe>
            <iframe
              className="my-8"
              width="530"
              height="315"
              src="https://www.youtube.com/embed/W87vrjdauBY?si=Zrrg8ircZxneYC2k"
              // allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            ></iframe>
            <iframe
              className="my-8"
              width="530"
              height="315"
              src="https://www.youtube.com/embed/s2yxUy5A2xc?si=oioQY3bxuTNBslHJ"
              // allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            ></iframe>
            <iframe
              className="my-8"
              width="530"
              height="315"
              src="https://www.youtube.com/embed/J7iEgQCkKTE?si=NIvbl9lWq9aWQsEV"
              // allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            ></iframe>
            <iframe
              className="my-8"
              width="530"
              height="315"
              src="https://www.youtube.com/embed/ImLc5yDkyys?si=P94EXM8LUNVNnRmt"
              // allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            ></iframe>
            <iframe
              className="my-8"
              width="530"
              height="315"
              src="https://www.youtube.com/embed/4vGfPp4hKVw?si=rlhsTabT-hKo8Gxs"
              // allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            ></iframe>
            <iframe
              className="my-8"
              width="530"
              height="315"
              src="https://www.youtube.com/embed/5CdGAiAKCUQ?si=lgMdPq4MLWmRHfyF"
              // allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            ></iframe>
            <iframe
              className="my-8"
              width="530"
              height="315"
              src="https://www.youtube.com/embed/SKhVsqs8bmA?si=AceoaIIKJJKjr3yM"
              // allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            ></iframe>

            <iframe
              className="my-8"
              width="530"
              height="315"
              src="https://www.youtube.com/embed/T2teiNSsj8U?si=IJMvp3hgAC3a5weD"
              // allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            ></iframe>

            <iframe
              className="my-8"
              width="530"
              height="315"
              src="https://www.youtube.com/embed/Fa4IYISNv5c?si=Xnr_w2OpmMTULvtJ"
              // allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            ></iframe>
            <iframe
              className="my-8"
              width="530"
              height="315"
              src="https://www.youtube.com/embed/G-Ety9rrrvA?si=pbo-NAeBr5n72aix"
              // allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            ></iframe> */}

            {/* <iframe
            width="530"
            height="315"
            src="https://www.youtube.com/embed/IdwVtz6i3Ag?si=iS6VemChPga60xWr"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default MyVideo;
