import React from 'react';
import { LiaHotdogSolid } from 'react-icons/lia';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <>
      <footer className="border-t border-slate-400 max-w-7xl mx-auto py-10 flex items-center justify-center text-center flex-col">
        <h1 className="text-slate-600 font-bold text-5xl">
          Ищи проекты в интернете
        </h1>
        <ul className="dark:text-slate-400 text-sm text-slate-600 mt-4 flex items-center justify-center flex-wrap gap-4">
          <li>
            <Link to="https://t.me/chertaduality_official">telegram</Link>
          </li>
          <li>
            <Link to="https://www.youtube.com/channel/UCaXttUBZmLfXPe0roX-w5Xw">
              youtube
            </Link>
          </li>
          <li>
            <Link to="https://music.yandex.ru/artist/21027248?utm_source=desktop&utm_medium=copy_link">
              ya.music
            </Link>
          </li>
          <li>
            <Link to="https://music.apple.com/ru/artist/cherta-duality/1716681107">
              apple music
            </Link>
          </li>
          <li>
            <Link to="https://zvuk.com/artist/212763080">sber zvuk</Link>
          </li>
          <li>
            <Link to="mailto:cherta.duality@yandex.ru">email</Link>
          </li>
        </ul>
        <p className="dark:text-slate-400 text-sm text-slate-600 mt-8 flex justify-center items-center text-center">
          &#169; Cherta Duality, 2024{' '}
          <LiaHotdogSolid className="text-red-700 text-2xl mx-2" />
        </p>
      </footer>
    </>
  );
};

export default Footer;
