import React, { useState } from 'react';
import openingVideo from './assets/vids/LOGO.mp4';
import { RiScrollToBottomLine } from 'react-icons/ri';

const Hero = () => {
  const scrollToBottom = () => {
    window.scroll({
      top: 800,
      bottom: 0,
      behavior: 'smooth',
    });
  };

  return (
    <>
      {window.screen.width > 1520 ? (
        <div className="items-center justify-center text-center mx-auto hidden xl:block ">
          <video
            className="w-full object-cover"
            autoPlay
            loop
            muted
            type="mp4"
            src={openingVideo}
          />
          <RiScrollToBottomLine
            className="transition-all duration-300  absolute text-slate-300 hover:text-purple-800 text-5xl z-10 top-[80%] left-[48%] animate-bounce cursor-pointer"
            onClick={scrollToBottom}
          />
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default Hero;
